// variables
@import './variables/colors';
@import './variables/mixins';

// theme styles files
@import './texts';
@import './xtras';

// custom css
* {
  font-family: 'Gilroy Medium', sans-serif;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $theme-secondary;
}

a {
  text-decoration: none !important;
}

p {
  margin: 0;
}

.bg-secondary {
  background-color: $theme-secondary !important;
}

.text-danger {
  color: $theme-danger-dark !important;
}

.text-gray {
  color: $gray-dark !important;
}

.spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  z-index: 1002;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.not-rounded {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  @include mobile {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    position: fixed;

    .spinner {
      position: static;
    }
  }
}

// circle
.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: -1px;
  margin-right: 2px;

  &.circle-red {
    background-color: $theme-danger-dark;
  }

  &.circle-green {
    background-color: $theme-success;
  }

  &.circle-primary {
    background-color: #2472e8;
  }

  &.circle-dark-blue {
    background-color: #174062;
  }

  &.circle-dark-gray {
    background-color: #777777;
  }

  &.circle-green-dark {
    background-color: $theme-success-dark;
  }

  &.circle-gray {
    background-color: $gray-9;
  }

  &.circle-skyblue {
    background-color: $theme-skyblue;
  }
  &.circle-purple {
    background-color: $theme-purple;
  }
}

.ongoing-circle {
  background-color: $theme-primary;
}

.expired-circle {
  background-color: $theme-danger-dark;
}

.btn-gray {
  background-color: $gray-e !important;
  color: $gray-7 !important;
  pointer-events: none;
}

.deactivate-btn {
  background-color: $theme-danger-bg !important;
  color: $theme-danger-dark !important;
}

.searchbox-section {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 30px;

  //  on mobile
  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }
}

.filter-btn {
  background-color: $extra-light;
  color: rgba($theme-secondary, 0.5);
  font-size: 0.9rem;
  padding: 0.4rem 1.5rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: rgba($theme-secondary, 0.5);
    background-color: darken($extra-light, 2%);
  }

  // filter-icon-style
  .filter-icon-style {
    width: 15px;
    margin-left: 10px;
    opacity: 0.6;
  }
}

.btn-danger {
  background-color: darken($theme-danger-bg, 2%) !important;
  color: $theme-danger-dark !important;
  box-shadow: none !important;

  &:hover {
    background-color: darken($theme-danger-bg, 4%);
    color: $theme-danger-dark;

    span {
      background: none !important;
    }
  }

  &:focus {
    background-color: darken($theme-danger-bg, 4%);
    color: $theme-danger-dark;
  }
}

.btn-danger:not(:disabled):not(.disabled):active {
  background-color: rgba($theme-danger-bg, 5%);
  color: $theme-danger-dark;
}

// .modal-backdrop
.modal {
  background-color: rgba($theme-secondary, 0.5);
  backdrop-filter: blur(2px);
}

// modaldate-picker
.modaldate-picker {
  .modal-dialog {
    max-width: 400px;
    margin: 1.75rem auto;
  }

  .calendar__item {
    padding: 8px;
  }

  .picker-input__text {
    border: none;
    background: none;
    width: 100%;
    font-size: 1rem;
    font-family: 'Gilroy Medium';
    color: $theme-secondary;
  }

  .picker-input {
    width: 100%;
  }

  .calendar {
    background: $extra-light;
    border-radius: 10px;
    overflow: hidden;

    &__container {
      width: auto;
    }
  }

  .calendar__head {
    background: $extra-light;
  }

  .calendar__head--title {
    font-size: 1rem;
    color: $theme-secondary;
  }
  .calendar__head--button svg {
    fill: $theme-secondary;
  }

  .calendar__day {
    vertical-align: middle;
    max-height: 30px;
    max-width: 30px;
    height: 41px;
    width: 30px;
    cursor: pointer;
    border-radius: 50%;
    padding-top: 0;
  }

  .calendar__day--start,
  .calendar__day--end,
  .calendar__day--selected {
    background: $theme-primary;
    color: $white !important;
  }

  .calendar__day--today {
    background: transparent;
    color: $gray-1;
  }

  .calendar__day--6,
  .calendar__day--0 {
    color: $theme-secondary;
  }

  .calendar__day:hover:not(.calendar__day--disabled) {
    background: $theme-primary;
    color: $white;
  }
}

// main-wrapper-section
.main-wrapper-section,
div[id='root'] {
  height: 100%;
}

.outer-404-page {
  margin: 0 40px;
}

// timepicker-style
.timepicker-style {
  .picker-input__text {
    max-height: 38px;
    background-color: $extra-light;
    border-radius: 5px;
    font-size: 0.85rem;
    font-family: 'Gilroy Medium';
  }
}

// remove modal style
.remove-modal {
  .modal-dialog {
    max-width: 360px;
  }
  // button-section
  .button-section {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // button-style
  .button-style {
    height: 32px;
    font-size: 0.75rem;
  }

  // text dark style
  .text-dark-style {
    text-align: center;
    max-width: 90%;
    margin: 5px auto 20px;
  }

  // modal-body
  .modal-body {
    text-align: center;
    padding: 2.5rem 0.5rem 1.5rem;
  }

  .modal-header {
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 9;
  }
}

// toggle-button-style
.toggle-button-style {
  display: flex;
  align-items: center;
  justify-content: center;
}

// chaticon-box
.chaticon-box {
  background-color: lighten($theme-success, 45%);
  height: 36px;
  width: 38px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  // btn-icon
  .btn-icon {
    color: $theme-success;
  }
}

// dropdown toggle button styles
.dropdown-btn-style {
  // dropdown-toggle
  .dropdown-toggle {
    border-radius: 10px;
    height: 38px;
    font-size: 0.9rem;
    overflow: hidden;

    &:focus {
      box-shadow: none !important;
    }

    &:after {
      margin-left: 20px;
      position: relative;
      z-index: 1;
    }

    &:before {
      background-color: $theme-primary;
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      width: 22%;
      height: 100%;
      border-radius: 0px 10px 10px 0;
    }
  }

  &.show {
    .dropdown-toggle::after {
      border-top: 0.3em solid transparent;
      border-right: 0.3em solid transparent;
      border-bottom: 0.3em solid;
      border-left: 0.3em solid transparent;
    }
  }

  // dropdown menu
  .dropdown-menu {
    border: none;

    &.show {
      display: block;
      background-color: lighten($theme-danger, 25%);
      border: none;
      min-width: 100px;
      border-radius: 0 0px 10px 10px;
      padding: 3px 0;

      .dropdown-item {
        color: $theme-danger;
        border-radius: 10px;
        background-color: lighten($theme-danger, 25%);
        font-size: 0.85rem;

        &:hover {
          background-color: lighten($theme-danger, 25%);
        }
      }
    }
  }
}

// table-card
.table-card {
  background-color: inherit;
  border: none;
  border-radius: 10px;
  overflow: hidden;

  th {
    font-size: 0.8rem;
    color: $gray-7;
    border: none;
    font-family: 'Gilroy Regular';
    vertical-align: middle;
  }

  td {
    border: none;
    font-size: 0.8rem;
    color: $gray-7;
    margin: 10px 0;
    vertical-align: middle;
  }

  .tb-tr-style {
    padding: 0 10px;
  }

  // table-header
  .table-header {
    background-color: #e1f2f7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px 2px 10px;

    // title
    .title {
      font-size: 1rem;
      text-transform: uppercase;
      margin: 0;
    }

    .button-outline-style {
      min-height: 27px;
      padding: 0px 0.5rem;
      font-size: 0.75rem;
      padding-top: 2px;

      @include mobile {
        font-size: 0.85rem;
      }
    }
  }

  .table-description {
    font-size: 0.75rem;
    padding-left: 11px;
    background-color: $theme-blue;
  }

  .table {
    margin: 0;
  }

  // button-default-style
  .button-default-style {
    min-height: 27px;
    padding: 0px 0.5rem;
    max-width: 60px;
  }
}

.close-btn,
.close-icon {
  padding: 0 !important;
}

// primary modal style
.primary-modal-style {
  // modal close btn
  .close-btn {
    right: 0;
    align-self: center;
    height: 25px;
    width: 25px;
    background: $extra-light;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 0.8rem;
    color: $gray-5;
    padding: 0;

    img {
      width: 22px;
      height: 22px;
    }
  }

  // modal title
  .modal-title {
    color: $theme-secondary;
    font-family: 'Gilroy SemiBold';

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }

    span {
      @include mobile {
        margin: 0;
      }
    }
  }

  // modal content
  .modal-content {
    border-radius: 1rem;
  }

  // modal header sec
  .modal-header {
    border: none;
    margin-bottom: 1rem;
  }

  // button-section
  .button-section {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// img icon white
.img-white {
  filter: contrast(0) brightness(100);
}

// img gray section
.img-gray {
  filter: contrast(0) brightness(0.5);
}

// switch-bg-style
.switch-bg-style {
  background-color: $extra-light;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  min-width: 158px;
  padding-bottom: 8px;
}

// from to styles
.from-to-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow-y: scroll;
  white-space: nowrap;
  padding-left: 40px;

  @include mobile {
    padding-left: 0;
    margin-top: 20px;
  }

  // scroll none
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  // &::after {
  //   position: absolute;
  //   top: 0;
  //   left: 15px;
  //   width: 20%;
  //   bottom: 0;
  //   content: '';
  //   display: block;
  //   background-image: linear-gradient(to right, $white 5%, transparent 30%);
  // }

  // &:before {
  //   position: absolute;
  //   top: 0;
  //   width: 11%;
  //   right: 15px;
  //   z-index: 1;
  //   bottom: 0;
  //   content: '';
  //   display: block;
  //   background-image: linear-gradient(to left, $white 5%, transparent 30%);
  // }

  // picker-input__text
  .picker-input__text {
    background-color: $extra-light;
    border: none;
    border-radius: 10px;
    max-width: 120px;
    font-size: 0.9rem;
  }

  // border-right
  .border-right-style {
    align-items: flex-end;
    justify-content: center;
    position: relative;

    &:after {
      content: '';
      width: 1px;
      height: 24px;
      background-color: $gray-light;
      margin: 0 18px 11px;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

// tab-pills-primary
.tab-pills-primary {
  // nav-pills`
  .nav-pills {
    background-color: $extra-light;
    border-radius: 10px;
    overflow: hidden;
  }

  // nav-item
  .nav-item {
    flex: 1;
    border-radius: 0;
    color: $gray-dark;
    text-align: center;

    .nav-link.active {
      color: $white;
      background-color: $theme-primary;

      @include mobile {
        font-size: 0.8rem;
      }
    }
  }

  .nav-link:hover,
  .nav-link:focus {
    outline: none;
  }

  // Assign Table Card
  .assign-table-card-section {
    // table card
    .table-card {
      background-color: #f3fcff;
    }
  }
}

// toggle-white-icon
.toggle-white-icon {
  //dropdown-toggle
  .dropdown-toggle {
    opacity: 1;
  }

  // dropdown menu
  .dropdown-menu {
    top: 9px !important;
    left: -107px !important;
  }

  .img-sec {
    filter: contrast(0) brightness(100);
  }

  .active-toggle {
    filter: none;
  }
}

// tab card common styles
.tab-card {
  background-color: #e1f2f7;
  border-radius: 10px;
  overflow: hidden;
  border: none;

  // tab content
  .tab-content {
    background-color: $tablebg;
    padding: 10px;
    margin-top: 10px;
  }

  .button-section {
    display: flex;
    justify-content: flex-end;
    margin: 10px;
  }

  // nav-link
  .nav-tabs .nav-link {
    border: none;
    background: none;

    &.active {
      background: none;
    }
  }

  .tab-pane {
    padding: 0;
  }

  .nav-tabs {
    position: relative;
    border: none;
    background-color: #e1f2f7;
    border-bottom: 1px solid $gray-e;

    @include mobile {
      white-space: nowrap;
      overflow: auto;
      flex-wrap: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .nav-tabs .nav-link {
    position: relative;
    margin-right: 0px;
    padding: 15px 20px;
    font-size: 0.9rem;
    width: 50%;
    text-transform: uppercase;
    text-align: center;
    border: none;
    color: rgba($theme-secondary, 0.5);
    border-radius: 0;
    background-color: transparent;
    margin-bottom: 0;
    outline: none;
    font-family: 'Gilroy Medium';

    @include mobile {
      margin-right: 5px;
      padding: 15px 0px;
      font-size: 0.75rem;
    }
  }
  .nav-tabs .nav-link:hover {
    background-color: transparent;
    border-bottom: 2px solid $theme-primary !important;
  }

  .nav-tabs .active {
    background-color: transparent;
    border-bottom: 2px solid $theme-primary !important;
    font-family: 'Gilroy SemiBold';
    color: $theme-secondary;
  }

  // upload care plan
  .upload-care-plan {
    // display: flex;
    // align-items: center;
    // margin: 0 10px;
    // label-primary

    // content-text
    .content-text {
      background-color: $extra-light;
      border-radius: 6px;
      width: 100%;
      height: 30px;
      font-size: 0.8rem;
      text-align: center;
      margin: 0 10px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba($theme-secondary, 0.5);
    }

    // btn
    .btn-style {
      background-color: $extra-light;
      width: 45px;
      height: 30px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;

      // icon
      .icon {
        color: $gray-dark;
        font-size: 0.85rem;
      }
    }

    .image-upload-box {
      height: 350px;
    }
    .img-preview {
      height: 350px;
    }
  }

  // form-container
  .form-container {
    padding-top: 15px;

    .form-control {
      background-color: $white;
      border: 2px solid #e1f2f7;
      border-radius: 10px;
    }
  }

  // input-container
  .input-container {
    margin: 0;
  }
}

// Assign Table Card
.assign-table-card-section {
  // table card
  .table-card {
    background-color: #e1f2f7;

    // table tr style
    .tb-tr-style {
      border: 10px solid lighten($theme-skyblue, 48%);
      border-left-width: 20px;
      border-right-width: 20px;

      &:first-child {
        border-top-width: 13px;
      }
    }

    // table-section
    .table-section {
      background-color: lighten($theme-skyblue, 48%);
      height: 509px;
      z-index: 0;
    }

    th {
      background-color: #e1f2f7;
    }

    td {
      background-color: $white;

      &:first-child {
        border-radius: 32px 0px 0px 32px;
      }

      &:last-child {
        border-radius: 0px 32px 32px 0px;
      }
    }
  }
}

.full-height {
  height: 100vh;
}

// title image
.title-image {
  border-radius: 10px;
  width: 34px;
  height: 34px;
  overflow: hidden;
  background-color: $light-blue-darker;
}

// onboarding content section
.onboarding-content-section {
  // margin-top: 12rem;

  .title3 {
    font-family: 'Gilroy SemiBold';
  }
}

// form-section
.form-section {
  position: relative;

  // invalid feedback error style
  .invalid-feedback {
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;

    @include mobile {
      position: relative;
    }
  }
}

// star text style
.mandatory-text {
  color: $theme-danger;
}

// upload signature file
.upload-signature {
  // upload file
  .fileContainer {
    height: 90px;
    width: 220px;
    margin: 0;
  }

  .fileContainer .uploadPictureContainer {
    height: 90px;
    width: 220px;
  }

  .fileContainer .chooseFileButton {
    height: 90px;
    width: 220px;
  }
}

// btn remove
.btn-gray-remove {
  background-color: $gray-e;
  color: $gray-dark;
  box-shadow: none !important;

  &:hover {
    background-color: darken($theme-danger-bg, 4%);
    color: $theme-danger-dark;

    span {
      background: none;
    }
  }

  &:focus {
    background-color: darken($theme-danger-bg, 4%);
    color: $theme-danger-dark;
  }
}

// img circle style
.img-sm-circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid $theme-gray;
  object-fit: cover;
}

// heading-section
.heading-section {
  .title4 {
    @include mobile {
      font-size: 1rem;
    }
  }
}

// modal style for mobile
.modal-dialog {
  .table-responsive {
    @include mobile {
      white-space: nowrap;
    }
  }
}

// Extrenal link style
.external-link {
  margin: 0 5px;
}

// chart responsive style
.chart-responsive {
  @include mobile {
    white-space: nowrap;
    overflow-y: hidden;
  }

  @include tablet {
    white-space: nowrap;
    overflow-y: hidden;
  }
}

// feedback modal
.feedback-toggle {
  .items-section {
    min-width: 151px;
  }
}

//link text
.link-disabled {
  pointer-events: none;
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgba(0, 174, 239, 0.15);
  border-right: 6px solid rgba(0, 174, 239, 0.15);
  border-bottom: 6px solid rgba(0, 174, 239, 0.15);
  border-top: 6px solid rgba(0, 174, 239, 0.8);
  border-radius: 100%;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
// Illustration section
.img-illustration1 {
  width: 480px;
  margin-bottom: 50px;

  @include mobile {
    width: 320px;
  }
}

// pills-section
.pills-section {
  display: flex;

  // on mobile
  @include mobile {
    flex-wrap: wrap;
  }

  .badge-pill {
    margin-bottom: 10px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.rotate180 {
  transform: rotate(180deg);
}

.Toastify__toast--success {
  background: #5bbd9d !important;
}

// Customize the container
// .Toastify__toast-container {
//   width: 320px;
// }

.Toastify__toast {
  border-radius: 4px !important;
}

// .table-responsive
.head-sticky-top {
  th {
    position: sticky;
    top: 0;
    z-index: 9;
  }
}

// overflow-x-hidden
.overflow-x-hidden {
  overflow-x: hidden !important;
}

.boxMask {
  position: relative;
  overflow: hidden;
}
.boxMask:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 220px;
  right: 0;
  border-radius: 200px 30px 30px 100px;
  z-index: -1;
  top: 0;
}

.boxMask-blue:after {
  background: #e0ecfc;
  width: 200px;
}
.boxMask-green:after {
  background: #e2f5f2;
  width: 200px;
}
.boxMask-green:after {
  background-color: #e2f5f2;
}
