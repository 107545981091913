@import '../../styles/variables/colors';
@import '../../styles/variables/mixins';

.booking-page-container {
  background-color: $theme-success-light;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .checkbox-container {
    margin-bottom: 16px;
  }

  // pattern-bg
  .pattern-bg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
  }

  // main heading
  .title1 {
    color: $theme-secondary;
    font-size: 30px;
  }

  .text-success {
    color: $theme-success;
    margin-top: 40px;
  }

  // text-lead-right
  .text-lead-right {
    color: $theme-secondary;
    opacity: 0.8;
  }

  // link-right
  .link-right {
    position: absolute;
    right: 0;
    @include tablet {
      position: static;
    }
  }

  // pattern-rect-style
  .pattern-rect-style {
    position: relative;

    &:after {
      position: absolute;
      top: 40px;
      left: 10px;
      width: 66px;
      height: 60px;
      content: '';
      background-image: url('../../assets/images/pattern/rect2.svg');
      background-repeat: no-repeat;
      opacity: 0.8;
    }

    &:before {
      position: absolute;
      bottom: 0;
      left: 40px;
      width: 118px;
      height: 106px;
      content: '';
      background-image: url('../../assets/images/pattern/rect1.svg');
      background-repeat: no-repeat;
      opacity: 0.5;
    }
  }

  .rect-center {
    position: absolute;
    top: 20%;
    right: 0;
    width: 120px;
    height: 130px;
    background-image: url('../../assets/images/pattern/rect3.svg');
    background-repeat: no-repeat;
    opacity: 0.5;
  }

  // button-style
  .button-style {
    span {
      background-color: $theme-primary;
      padding: 0 5px;
    }

    &:hover {
      span {
        background-color: darken($theme-primary, 5%);
      }
    }
  }

  .onboarding-content-section {
    margin-top: 0;
  }
  h6:after,
  h6:before {
    content: '\00a0\00a0\00a0\00a0\00a0';
    text-decoration: line-through;
  }

  .img-md-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: scale-down;
    // border: 4px solid $theme-cyan;
  }
  .img-md-circle:hover {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 4px solid $theme-cyan;
    object-fit: scale-down;
  }

  .img-md-circle-border {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 4px solid $theme-cyan;
    object-fit: contain;
  }
  .doctor-text {
    font-weight: bold;
    text-align: center;
    position: relative;
    margin-left: -7px;
  }
  .user-text {
    font-weight: bold;
    text-align: center;
  }
  .user-role-selection-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .booking-btn {
    // min-height: 45px;
    margin-left: 40px;
    margin-right: 40px;

    @include mobile {
      margin-left: 60px;
      margin-right: 60px;
    }
  }
}
