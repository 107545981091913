// ######################################## //
// ## TEXTS.SCSS ## //
// ######################################## //

// Font Primary

@font-face {
  font-family: 'Gilroy Light';
  font-style: normal;
  src: url('../assets/gilroy-fonts/Gilroy-Light.ttf'); /* IE9 Compat Modes */
  src: url('../assets/gilroy-fonts/Gilroy-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy Regular';
  font-style: normal;
  src: url('../assets/gilroy-fonts/Gilroy-Regular.ttf'); /* IE9 Compat Modes */
  src: url('../assets/gilroy-fonts/Gilroy-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy Medium';
  font-style: normal;
  src: url('../assets/gilroy-fonts/Gilroy-medium-webfont.woff') format('woff2'); /* IE9 Compat Modes */
  src: url('../assets/gilroy-fonts/Gilroy-medium-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy SemiBold';
  font-style: normal;
  src: url('../assets/gilroy-fonts/Gilroy-semibold-webfont.woff') format('woff2'); /* IE9 Compat Modes */
  src: url('../assets/gilroy-fonts/Gilroy-semibold-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy Bold';
  font-style: normal;
  src: url('../assets/gilroy-fonts/Gilroy-bold-webfont.woff') format('woff2'); /* IE9 Compat Modes */
  src: url('../assets/gilroy-fonts/Gilroy-bold-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy Heavy';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/gilroy-fonts/Gilroy-Heavy.ttf'); /* IE9 Compat Modes */
  src: url('../assets/gilroy-fonts/Gilroy-Heavy.ttf') format('truetype');
}

// Text Primary
.text-primary {
  color: $theme-primary !important;
}

// Text Secondary
.text-secondary {
  color: rgba($theme-secondary, 0.71) !important;
}

// Text Info
.text-info {
  color: $theme-info !important;
}

// Text Success
.text-success {
  color: $theme-success !important;
}

// Text Danger
.text-danger {
  color: $theme-danger !important;
}

// Text Cyan
.text-cyan {
  color: darken($theme-cyan, 8%) !important;
}

// Text Yellow
.text-yellow {
  color: darken($theme-yellow, 10%) !important;
}

// Heading style
.title1,
.title2,
.title3,
.title4,
.title5,
.title6 {
  margin: 0;
  line-height: 1.4;
}

// styles
.title1 {
  font-size: 1.9rem;
  font-family: 'Gilroy Bold';
}

.title2 {
  font-size: 1.8rem;
  font-family: 'Gilroy SemiBold';
}

.title3 {
  font-size: 1.5rem;
  font-family: 'Gilroy Medium';
}

.title4 {
  font-size: 1.3rem;
  font-family: 'Gilroy Medium';
}

.title5 {
  font-size: 1.2rem;
  font-family: 'Gilroy Medium';
}

.title6 {
  font-size: 1rem;
  font-family: 'Gilroy Medium';
}

.lead {
  font-size: 1rem;
  color: $gray-dark;
  line-height: 1.6;
  font-family: 'Gilroy Regular';
}

.info-text {
  font-size: 0.8rem;
  color: $gray-dark;
  line-height: 1.6;
  font-family: 'Gilroy Regular';
}

.text-dark-style {
  font-size: 0.9rem;
  color: $theme-secondary;
  line-height: 1.6;
  font-family: 'Gilroy Medium';
}

.text-link {
  font-size: 0.9rem;
  color: $theme-primary;
  line-height: 1.6;
  text-decoration: none !important;
}

.input-primary {
  font-size: 0.93rem;
  color: $theme-secondary;
  line-height: 1.5;
}

// time-title
.time-title {
  font-size: 0.8rem;
  color: $theme-secondary;
  line-height: 1.6;
  font-family: 'Gilroy SemiBold';
}
