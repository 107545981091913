// ######################################## //
// ## COLORS.SCSS ## //
// ######################################## //

// Black (Gray) Color Shades
$black: #000000;
$gray-1: #111111;
$gray-2: #222222;
$gray-3: #333333;
$gray-4: #444444;
$gray-5: #555555;
$gray-6: #666666;
$gray-7: #777777;
$gray-8: #888888;
$gray-9: #999999;
$gray-a: #aaaaaa;
$gray-b: #bbbbbb;
$gray-c: #cccccc;
$gray-d: #dddddd;
$gray-e: #eeeeee;
$white: #ffffff;
$extra-light: #f6f7fb;
$gray-light: #b1b5c0;
$gray-dark: #7a839a;
$light-blue: #e8f1fd;
$light-blue-darker: #d7f0ff;
$tablebg: #f5fcfe;
$picture-border-color: #667786bf;

// Color Shades (Theme Based - Xclusive)
$theme-primary: #2472e8;
$theme-primary-faded: #113655;
$theme-primary-light: #5b95ee;
$theme-blue: #e1f2f7;
$theme-highlighted: #d3e3fa;
$theme-primary-lighter: #cfeefe;
$theme-secondary: #0e2940;
$theme-success: #1abc9c;
$theme-success-light: #8bddcd;
$theme-success-bright: #e7f8f5;
$theme-success-dark: #168d75;
$theme-success-green: #34c75a;
$theme-info: #9c6dff;
$theme-info-bg: #f7f4fe;
$theme-warning: #ff8238;
$theme-warning-bg: #fffaf7;
$theme-success-bg: #f2fff4;
$theme-danger-lighter:#feedee;
$theme-danger: #f96464;
$theme-danger-dark: #e74356;
$theme-danger-bg: #fff5f5;
$theme-yellow: #e3c040;
$theme-yellow-bg: #fdfcf6;
$theme-cyan: #35d2cc;
$theme-cyan-light: #9be2f233;
$theme-cyan-bg: #f6fdfd;
$theme-pink: #c83375;
$theme-pink-bg: #fff8f9;
$theme-gray: #919da8;
$theme-link: #004b8f;
$theme-skyblue: #02b7fb;
$theme-purple: #b469ff;
